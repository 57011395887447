/**
 * Constantes de rotas
 */
export const ROUTES = {
  /**
   * Rotas dos Paineis
   */
  PAINEL: {
    /**
     * Rota do Painel do trabalhador
     */
    CANDIDATE: "/painel/trabalhador",
    /**
     * Rota do Painel da empresa
     */
    COMPANY: "/painel/empresa",
    /**
     * Rota do Painel do Administrador
     */
    ADMIN: "/painel/admin",
    /**
     * Rota do Painel do Funtrab
     */
    FUNTRAB: "/painel/funtrab",
    /**
     * Rota do Painel do SEMADESC
     */
    SEMADESC: "/painel/semadesc",
    /**
     * Rota do Painel do Qualificação
     */
    STUDENT: "/painel/aluno",

    /**
     * Rota do Painel de IEP
     */
    IEP: "/painel/iep",
  },
  /**
   * Rota da página inicial
   */
  HOME: "/",
  /**
   * Rota da página de busca de vagas sem login
   */
  HOME_BUSCAVAGAS: "/busca-vaga",
  /**
   * Rota da página de privacidade
   */
  PRIVACY: "/politica-de-privacidade",
  /**
   * Rota da página indexada do Job
   */
  JOB_PAGE_ROUTE: "/vagas/msqualifica/detalhe-vaga/",
  /**
   * Rota da página indexada do JobFuntrab
   */
  JOB_FUNTRAB_PAGE_ROUTE: "/vagas/funtrab/detalhe-vaga/",
}
